import React from 'react';

const MovieIcon = ({ size = 19, color = '#D42525' }) => {
  return (
    <svg
      width={size}
      height={(size * 18) / 19}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16398 8.35156C8.7386 8.10572 8.21437 8.10551 7.7888 8.35102C7.3632 8.59654 7.10094 9.05048 7.10083 9.54182V12.6238C7.10093 13.1151 7.3632 13.5693 7.7888 13.8148C8.21441 14.0603 8.73868 14.0601 9.16407 13.8142L11.8304 12.2734C12.2555 12.0277 12.5175 11.5739 12.5175 11.0829C12.5175 10.5919 12.2556 10.1381 11.8305 9.89246L9.16398 8.35156ZM8.7675 10.0474L10.5595 11.0829L8.7675 12.1184V10.0474Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18379 0.666219H10.3138C10.326 0.665948 10.3382 0.665947 10.3505 0.666219H14.4804C14.4926 0.665948 14.5049 0.665947 14.5171 0.666219H16.1654C17.5461 0.666219 18.6654 1.78551 18.6654 3.16622V14.8329C18.6654 16.2136 17.5461 17.3329 16.1654 17.3329H2.83203C1.45132 17.3329 0.332031 16.2136 0.332031 14.8329V3.16622C0.332031 1.78551 1.45132 0.666219 2.83203 0.666219H6.14708C6.15929 0.665948 6.17153 0.665947 6.18379 0.666219ZM1.9987 6.49955V14.8329C1.9987 15.2931 2.37179 15.6662 2.83203 15.6662H16.1654C16.6256 15.6662 16.9987 15.2931 16.9987 14.8329V6.49955H12.8515C12.8383 6.49987 12.825 6.49987 12.8117 6.49955H8.68486C8.6716 6.49987 8.65831 6.49987 8.64499 6.49955H4.51819C4.50493 6.49987 4.49164 6.49987 4.47832 6.49955H1.9987ZM16.9987 4.83289V3.16622C16.9987 2.70598 16.6256 2.33289 16.1654 2.33289H15.0627L14.0627 4.83289H16.9987ZM13.2677 2.33289H10.896L9.89605 4.83289H12.2677L13.2677 2.33289ZM9.10099 2.33289H6.72938L5.72938 4.83289H8.10099L9.10099 2.33289ZM4.93433 2.33289L3.93433 4.83289H1.9987V3.16622C1.9987 2.70598 2.37179 2.33289 2.83203 2.33289H4.93433Z"
        fill={color}
      />
    </svg>
  );
};

export default MovieIcon;

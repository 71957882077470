import React from 'react';

const IconsContentBookRead = ({ size = 24, color = '#313A44' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 6.519C4.75 6.09429 5.09429 5.75 5.519 5.75H7.92479C9.78847 5.87833 11.2362 7.42147 11.2501 9.28505C11.25 9.30297 11.25 9.32091 11.2501 9.33887C11.2501 9.34164 11.25 9.34442 11.25 9.34719V18.0197C10.5224 17.5117 9.6445 17.2247 8.723 17.2247H5.519C5.09431 17.2247 4.75002 16.8804 4.75 16.4557V6.519ZM12.0001 20.7501C11.7209 20.7502 11.4583 20.5937 11.3292 20.3354C10.8356 19.3483 9.82666 18.7247 8.723 18.7247H5.519C4.26589 18.7247 3.25003 17.7089 3.25 16.4557V6.519C3.25 5.26587 4.26587 4.25 5.519 4.25H7.94937C7.96502 4.25 7.98067 4.25049 7.99629 4.25147C9.70059 4.35829 11.1632 5.29793 12.0001 6.65755C12.837 5.29793 14.2996 4.35829 16.0039 4.25147C16.0196 4.25049 16.0352 4.25 16.0509 4.25H18.4813C19.7344 4.25005 20.7502 5.2659 20.7502 6.519V16.4557C20.7502 17.7088 19.7343 18.7247 18.4812 18.7247H15.2772C14.1735 18.7247 13.1646 19.3483 12.671 20.3354C12.5419 20.5937 12.2793 20.7502 12.0001 20.7501ZM12.7501 9.34151C12.7501 9.34341 12.7502 9.3453 12.7502 9.3472L12.7502 9.35444L12.7502 18.0197C13.4778 17.5117 14.3557 17.2247 15.2772 17.2247H18.4812C18.9059 17.2247 19.2502 16.8804 19.2502 16.4557V6.519C19.2502 6.09431 18.9059 5.75003 18.4813 5.75H16.0754C14.2126 5.87827 12.7653 7.42001 12.7501 9.28241C12.7502 9.30209 12.7502 9.32179 12.7501 9.34151Z"
        fill={color}
      />
    </svg>
  );
};

export default IconsContentBookRead;

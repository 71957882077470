import * as React from 'react';

const IconsCourseGoal = ({ size = 24, color = '#313A44', ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2215 1.27823C18.4861 1.35827 18.6853 1.57746 18.7397 1.84856L19.2975 4.62841L22.1685 5.26559C22.4358 5.32491 22.6495 5.52515 22.7261 5.78799C22.8026 6.05083 22.7299 6.33452 22.5363 6.52811L18.5347 10.5298C18.394 10.6704 18.2033 10.7494 18.0043 10.7494H14.3133L12.062 13.0008C11.7691 13.2937 11.2942 13.2937 11.0013 13.0008C10.7084 12.7079 10.7084 12.233 11.0013 11.9401L13.2527 9.68878V5.99778C13.2527 5.79887 13.3317 5.6081 13.4723 5.46745L17.474 1.46578C17.6695 1.27026 17.9568 1.19818 18.2215 1.27823ZM14.7527 9.24945H17.6937L20.5093 6.43385L18.4957 5.98697C18.2059 5.92265 17.9813 5.69342 17.9229 5.40234L17.5445 3.51663L14.7527 6.30844V9.24945ZM11.75 3.92599C11.7754 4.33943 11.4608 4.69515 11.0474 4.72053C6.88542 4.97597 3.66631 8.46968 3.75166 12.6386C3.83701 16.8075 7.19641 20.1665 11.3653 20.2514C15.5343 20.3363 19.0276 17.1168 19.2826 12.9548C19.3079 12.5414 19.6636 12.2268 20.077 12.2521C20.4905 12.2774 20.8051 12.6331 20.7798 13.0466C20.4756 18.0116 16.3082 21.8524 11.3348 21.7511C6.36143 21.6498 2.3538 17.6426 2.25198 12.6693C2.15016 7.69594 5.99043 3.52808 10.9555 3.22334C11.3689 3.19797 11.7246 3.51255 11.75 3.92599ZM11.747 7.70711C11.792 8.11888 11.4946 8.48913 11.0828 8.53409C9.01053 8.76039 7.46464 10.5485 7.5402 12.6317C7.61577 14.715 9.28714 16.3864 11.3704 16.4619C13.4537 16.5375 15.2417 14.9916 15.468 12.9193C15.513 12.5075 15.8832 12.2102 16.295 12.2551C16.7068 12.3001 17.0041 12.6703 16.9592 13.0821C16.6475 15.936 14.185 18.065 11.316 17.9609C8.44703 17.8569 6.14526 15.5551 6.04119 12.6861C5.93712 9.81709 8.06608 7.35461 10.92 7.04296C11.3318 6.99799 11.702 7.29534 11.747 7.70711Z"
        fill={color}
      />
    </svg>
  );
};

export default IconsCourseGoal;

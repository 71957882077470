import * as React from 'react';

const IconsTimeCalendarScheduleDate = ({
  size = 24,
  color = '#313A44',
  ...rest
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.435 4.10845C12.3254 3.46518 10.9554 3.46613 9.84654 4.11129L9.84414 4.11268L5.52484 6.62651L5.51585 6.63166C4.42252 7.27267 3.75 8.44531 3.75 9.71327V14.2867V14.2867C3.75 15.4224 4.28946 16.4815 5.18822 17.1508C6.15563 15.9471 8.31521 14.25 11.5001 14.25C14.8014 14.25 17.0011 16.0734 17.9133 17.2807C18.9193 16.6261 19.5331 15.5063 19.5372 14.2988L19.5371 14.2867V9.71326L19.5372 9.70196C19.5332 8.43492 18.8578 7.26445 17.7616 6.62651L13.4398 4.11122L13.435 4.10845ZM16.6075 18.0458C15.864 17.142 14.1153 15.75 11.5001 15.75C8.99092 15.75 7.27946 17.0315 6.48809 17.9336L9.84399 19.8867L9.84752 19.8887C10.9567 20.5341 12.3274 20.5348 13.4372 19.8909L13.4407 19.8888L15.6016 18.6312L16.6075 18.0458ZM2.25 9.71327V14.2867V14.2867C2.25 16.0925 3.21037 17.7616 4.77118 18.67L4.7809 18.6755L9.0931 21.1852L9.09754 21.1878C10.6735 22.1027 12.62 22.1018 14.1953 21.1852L14.1994 21.1828L18.5171 18.6699L18.5192 18.6687C20.0778 17.7601 21.0372 16.0916 21.0372 14.2867L21.0371 14.2734V9.72571L21.0372 9.71327C21.0372 7.90753 20.0768 6.23836 18.516 5.33005L18.512 5.32774L14.1992 2.81765L14.1943 2.81478C12.6182 1.89775 10.6706 1.89735 9.09425 2.81357L9.09213 2.8148L4.78039 5.32423L4.77002 5.33017C3.2103 6.2385 2.25001 7.90758 2.25 9.71325V9.71326V9.71327ZM11.5 7.75C10.5335 7.75 9.75 8.5335 9.75 9.5C9.75 10.4665 10.5335 11.25 11.5 11.25C12.4665 11.25 13.25 10.4665 13.25 9.5C13.25 8.5335 12.4665 7.75 11.5 7.75ZM8.25 9.5C8.25 7.70507 9.70507 6.25 11.5 6.25C13.2949 6.25 14.75 7.70507 14.75 9.5C14.75 11.2949 13.2949 12.75 11.5 12.75C9.70507 12.75 8.25 11.2949 8.25 9.5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconsTimeCalendarScheduleDate;

import React from 'react';

const CheckMarkIcon = ({ color = '#D42525', ...rest }) => {
  return (
    <svg
      {...rest}
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.41764 7.28766L1.62175 4.5972C1.30752 4.29482 0.79991 4.29482 0.485676 4.5972C0.171441 4.89959 0.171441 5.38805 0.485676 5.69044L3.85363 8.93139C4.16786 9.23378 4.67547 9.23378 4.9897 8.93139L12.6361 1.62667C12.9503 1.32428 12.9503 0.835816 12.6361 0.53343C12.3218 0.231044 11.8142 0.231044 11.5 0.53343L4.41764 7.28766Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckMarkIcon;

import React from 'react';

const BookSparkleIcon = ({ size = 32, color = '#D42525' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33073 3C5.30569 3 3.66406 4.64162 3.66406 6.66667V25.3333C3.66406 27.3584 5.30569 29 7.33073 29H16.6641C17.2163 29 17.6641 28.5523 17.6641 28C17.6641 27.4477 17.2163 27 16.6641 27H7.33073C6.41025 27 5.66406 26.2538 5.66406 25.3333C5.66406 24.4129 6.41025 23.6667 7.33073 23.6667H13.9974C14.5497 23.6667 14.9974 23.219 14.9974 22.6667C14.9974 22.1144 14.5497 21.6667 13.9974 21.6667H7.33073C6.73058 21.6667 6.16411 21.8109 5.66406 22.0665V6.66667C5.66406 5.74619 6.41025 5 7.33073 5H23.3307C24.2512 5 24.9974 5.74619 24.9974 6.66667V13.3333C24.9974 13.8856 25.4451 14.3333 25.9974 14.3333C26.5497 14.3333 26.9974 13.8856 26.9974 13.3333V6.66667C26.9974 4.64162 25.3558 3 23.3307 3H7.33073ZM23.7491 17.4923C23.7987 17.2803 24.1961 17.2803 24.2457 17.4923C24.9801 20.6314 26.6993 22.3506 29.8384 23.085C30.0504 23.1346 30.0504 23.5321 29.8384 23.5816C26.6993 24.3161 24.9801 26.0352 24.2457 29.1744C24.1961 29.3863 23.7987 29.3863 23.7491 29.1744C23.0147 26.0352 21.2955 24.3161 18.1564 23.5816C17.9444 23.5321 17.9444 23.1346 18.1564 23.085C21.2955 22.3506 23.0147 20.6314 23.7491 17.4923ZM15.4687 10.0883C15.4411 9.97056 15.2203 9.97056 15.1928 10.0883C14.7848 11.8323 13.8297 12.7874 12.0857 13.1954C11.968 13.2229 11.968 13.4437 12.0857 13.4713C13.8297 13.8793 14.7848 14.8344 15.1928 16.5784C15.2203 16.6961 15.4411 16.6961 15.4687 16.5784C15.8767 14.8344 16.8318 13.8793 18.5757 13.4713C18.6935 13.4437 18.6935 13.2229 18.5757 13.1954C16.8318 12.7874 15.8767 11.8323 15.4687 10.0883Z"
        fill={color}
      />
    </svg>
  );
};

export default BookSparkleIcon;

import * as React from 'react';

const IconsTimeCalendarScheduleDate = ({
  size = 24,
  color = '#313A44',
  ...rest
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.25C7.91421 2.25 8.25 2.58579 8.25 3V3.78842H15.75V3C15.75 2.58579 16.0858 2.25 16.5 2.25C16.9142 2.25 17.25 2.58579 17.25 3V3.78842H18C20.0856 3.78842 21.75 5.45278 21.75 7.53842V8.99692V15.008V18C21.75 20.0358 20.0926 21.75 18 21.75H6C3.90741 21.75 2.25 20.0358 2.25 18V15.008V8.99692V7.53842C2.25 5.45278 3.91436 3.78842 6 3.78842H6.75V3C6.75 2.58579 7.08579 2.25 7.5 2.25ZM3.75 15.758V18C3.75 19.2213 4.74973 20.25 6 20.25H8.24511V15.758H3.75ZM14.3196 20.25H9.74511V15.758H14.3196V20.25ZM15.8196 20.25H18C19.2503 20.25 20.25 19.2213 20.25 18V15.758H15.8196V20.25ZM15.8196 14.258H20.25V9.74692H15.8196V14.258ZM14.3196 9.74692H9.74511V14.258H14.3196V9.74692ZM3.75 9.74692H8.24511V14.258H3.75V9.74692ZM20.25 7.53842V8.24692H3.75V7.53842C3.75 6.2812 4.74278 5.28842 6 5.28842H6.75V6C6.75 6.41421 7.08579 6.75 7.5 6.75C7.91421 6.75 8.25 6.41421 8.25 6V5.28842H15.75V6C15.75 6.41421 16.0858 6.75 16.5 6.75C16.9142 6.75 17.25 6.41421 17.25 6V5.28842H18C19.2572 5.28842 20.25 6.2812 20.25 7.53842Z"
        fill={color}
      />
    </svg>
  );
};

export default IconsTimeCalendarScheduleDate;

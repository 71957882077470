import React from 'react';

const DemoBookIcon = ({ size = 24, color = '#2B52D4' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.71094C4.92879 2.71094 3.25 4.38972 3.25 6.46094V18.4609C3.25 20.5322 4.92879 22.2109 7 22.2109H19C19.9662 22.2109 20.75 21.4272 20.75 20.4609V18.4609V15.4609V4.46094C20.75 3.49472 19.9662 2.71094 19 2.71094H7ZM19.25 14.7109H7.75V4.21094H19C19.1378 4.21094 19.25 4.32315 19.25 4.46094V14.7109ZM4.75 15.4606V6.46094C4.75 5.48114 5.37604 4.64776 6.25 4.33893V14.7859C5.69583 14.8984 5.18605 15.1331 4.75 15.4606ZM4.75 18.4609C4.75 19.7037 5.75721 20.7109 7 20.7109H19C19.1378 20.7109 19.25 20.5987 19.25 20.4609V19.2109H7C6.58579 19.2109 6.25 18.8752 6.25 18.4609C6.25 18.0467 6.58579 17.7109 7 17.7109H19.25V16.2109H7C5.75721 16.2109 4.75 17.2182 4.75 18.4609ZM10.25 7.46094C10.25 7.04672 10.5858 6.71094 11 6.71094H15C15.4142 6.71094 15.75 7.04672 15.75 7.46094C15.75 7.87515 15.4142 8.21094 15 8.21094H11C10.5858 8.21094 10.25 7.87515 10.25 7.46094ZM11 10.7109C10.5858 10.7109 10.25 11.0467 10.25 11.4609C10.25 11.8752 10.5858 12.2109 11 12.2109H15C15.4142 12.2109 15.75 11.8752 15.75 11.4609C15.75 11.0467 15.4142 10.7109 15 10.7109H11Z"
        fill={color}
      />
    </svg>
  );
};

export default DemoBookIcon;

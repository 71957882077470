import React from 'react';

const ChatBubbleIcon = ({ size = 24, color = '#5A6877' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79525 11.9992C3.79525 7.46675 7.46872 3.79328 12.0011 3.79328C16.5336 3.79328 20.207 7.46675 20.207 11.9992C20.207 16.5316 16.5336 20.2051 12.0011 20.2051C10.5445 20.2051 9.17986 19.8217 7.99396 19.1558C7.81721 19.0565 7.6092 19.0286 7.41255 19.0778L4.09253 19.9078L4.92254 16.5878C4.9717 16.3911 4.9438 16.1831 4.84455 16.0064C4.17862 14.8205 3.79525 13.4558 3.79525 11.9992ZM12.0011 2.20508C6.59158 2.20508 2.20705 6.58961 2.20705 11.9992C2.20705 13.6239 2.60718 15.1542 3.30705 16.5014L2.23076 20.8066C2.16311 21.0772 2.2424 21.3635 2.43963 21.5607C2.63687 21.7579 2.92314 21.8372 3.19375 21.7696L7.49892 20.6933C8.84608 21.3932 10.3765 21.7933 12.0011 21.7933C17.4107 21.7933 21.7952 17.4087 21.7952 11.9992C21.7952 6.58961 17.4107 2.20508 12.0011 2.20508ZM12.1261 10.9992C11.6419 10.9992 11.2511 11.39 11.2511 11.8742C11.2511 12.3584 11.6419 12.7492 12.1261 12.7492C12.6103 12.7492 13.0011 12.3584 13.0011 11.8742C13.0011 11.39 12.6103 10.9992 12.1261 10.9992ZM14.5011 11.8742C14.5011 11.39 14.8919 10.9992 15.3761 10.9992C15.8603 10.9992 16.2511 11.39 16.2511 11.8742C16.2511 12.3584 15.8603 12.7492 15.3761 12.7492C14.8919 12.7492 14.5011 12.3584 14.5011 11.8742ZM8.8761 10.9992C8.39189 10.9992 8.0011 11.39 8.0011 11.8742C8.0011 12.3584 8.39189 12.7492 8.8761 12.7492C9.36031 12.7492 9.7511 12.3584 9.7511 11.8742C9.7511 11.39 9.36031 10.9992 8.8761 10.9992Z"
        fill={color}
      />
    </svg>
  );
};

export default ChatBubbleIcon;
